var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
import { getNamedType, GraphQLInputObjectType, getNullableType, GraphQLList, GraphQLEnumType, isCompositeType } from "graphql";
import { S as SchemaMetaFieldDef, T as TypeMetaFieldDef, a as TypeNameMetaFieldDef } from "./introspection.es.js";
import { f as forEachState } from "./forEachState.es.js";
function getTypeInfo(schema, tokenState) {
  const info = {
    schema,
    type: null,
    parentType: null,
    inputType: null,
    directiveDef: null,
    fieldDef: null,
    argDef: null,
    argDefs: null,
    objectFieldDefs: null
  };
  forEachState(tokenState, (state) => {
    var _a, _b;
    switch (state.kind) {
      case "Query":
      case "ShortQuery":
        info.type = schema.getQueryType();
        break;
      case "Mutation":
        info.type = schema.getMutationType();
        break;
      case "Subscription":
        info.type = schema.getSubscriptionType();
        break;
      case "InlineFragment":
      case "FragmentDefinition":
        if (state.type) {
          info.type = schema.getType(state.type);
        }
        break;
      case "Field":
      case "AliasedField":
        info.fieldDef = info.type && state.name ? getFieldDef(schema, info.parentType, state.name) : null;
        info.type = (_a = info.fieldDef) === null || _a === void 0 ? void 0 : _a.type;
        break;
      case "SelectionSet":
        info.parentType = info.type ? getNamedType(info.type) : null;
        break;
      case "Directive":
        info.directiveDef = state.name ? schema.getDirective(state.name) : null;
        break;
      case "Arguments":
        const parentDef = state.prevState ? state.prevState.kind === "Field" ? info.fieldDef : state.prevState.kind === "Directive" ? info.directiveDef : state.prevState.kind === "AliasedField" ? state.prevState.name && getFieldDef(schema, info.parentType, state.prevState.name) : null : null;
        info.argDefs = parentDef ? parentDef.args : null;
        break;
      case "Argument":
        info.argDef = null;
        if (info.argDefs) {
          for (let i = 0; i < info.argDefs.length; i++) {
            if (info.argDefs[i].name === state.name) {
              info.argDef = info.argDefs[i];
              break;
            }
          }
        }
        info.inputType = (_b = info.argDef) === null || _b === void 0 ? void 0 : _b.type;
        break;
      case "EnumValue":
        const enumType = info.inputType ? getNamedType(info.inputType) : null;
        info.enumValue = enumType instanceof GraphQLEnumType ? find(enumType.getValues(), (val) => val.value === state.name) : null;
        break;
      case "ListValue":
        const nullableType = info.inputType ? getNullableType(info.inputType) : null;
        info.inputType = nullableType instanceof GraphQLList ? nullableType.ofType : null;
        break;
      case "ObjectValue":
        const objectType = info.inputType ? getNamedType(info.inputType) : null;
        info.objectFieldDefs = objectType instanceof GraphQLInputObjectType ? objectType.getFields() : null;
        break;
      case "ObjectField":
        const objectField = state.name && info.objectFieldDefs ? info.objectFieldDefs[state.name] : null;
        info.inputType = objectField === null || objectField === void 0 ? void 0 : objectField.type;
        break;
      case "NamedType":
        info.type = state.name ? schema.getType(state.name) : null;
        break;
    }
  });
  return info;
}
__name(getTypeInfo, "getTypeInfo");
function getFieldDef(schema, type, fieldName) {
  if (fieldName === SchemaMetaFieldDef.name && schema.getQueryType() === type) {
    return SchemaMetaFieldDef;
  }
  if (fieldName === TypeMetaFieldDef.name && schema.getQueryType() === type) {
    return TypeMetaFieldDef;
  }
  if (fieldName === TypeNameMetaFieldDef.name && isCompositeType(type)) {
    return TypeNameMetaFieldDef;
  }
  if (type && type.getFields) {
    return type.getFields()[fieldName];
  }
}
__name(getFieldDef, "getFieldDef");
function find(array, predicate) {
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
}
__name(find, "find");
function getFieldReference(typeInfo) {
  return {
    kind: "Field",
    schema: typeInfo.schema,
    field: typeInfo.fieldDef,
    type: isMetaField(typeInfo.fieldDef) ? null : typeInfo.parentType
  };
}
__name(getFieldReference, "getFieldReference");
function getDirectiveReference(typeInfo) {
  return {
    kind: "Directive",
    schema: typeInfo.schema,
    directive: typeInfo.directiveDef
  };
}
__name(getDirectiveReference, "getDirectiveReference");
function getArgumentReference(typeInfo) {
  return typeInfo.directiveDef ? {
    kind: "Argument",
    schema: typeInfo.schema,
    argument: typeInfo.argDef,
    directive: typeInfo.directiveDef
  } : {
    kind: "Argument",
    schema: typeInfo.schema,
    argument: typeInfo.argDef,
    field: typeInfo.fieldDef,
    type: isMetaField(typeInfo.fieldDef) ? null : typeInfo.parentType
  };
}
__name(getArgumentReference, "getArgumentReference");
function getEnumValueReference(typeInfo) {
  return {
    kind: "EnumValue",
    value: typeInfo.enumValue || void 0,
    type: typeInfo.inputType ? getNamedType(typeInfo.inputType) : void 0
  };
}
__name(getEnumValueReference, "getEnumValueReference");
function getTypeReference(typeInfo, type) {
  return {
    kind: "Type",
    schema: typeInfo.schema,
    type: type || typeInfo.type
  };
}
__name(getTypeReference, "getTypeReference");
function isMetaField(fieldDef) {
  return fieldDef.name.slice(0, 2) === "__";
}
__name(isMetaField, "isMetaField");
export { getFieldReference as a, getDirectiveReference as b, getArgumentReference as c, getEnumValueReference as d, getTypeReference as e, getTypeInfo as g };
